<template>
  <div class="img-created">
    <div class="block" @dragover="isInputAdd = false" @mouseover="isInputAdd = false" :style="style">
      <input
        type="file"
        :class="{ inputAdd: isInputAdd }"
        :accept="dataImg.type"
        :disabled="disabledShow"
        multiple
        @change="previewFileAll"
      />
      <b-icon v-show="isInputshow" icon="download" style="color: #83b1f4" class="img-icon" />
      <p v-show="isInputshow" class="img-title">
        {{ dataImg.title }}
      </p>
      <p v-show="isInputshow" class="img-text">или</p>
      <button v-show="isInputshow" class="img-btn">
        {{ dataImg.btn }}
      </button>
      <p v-show="isInputshow" class="img-text">Максимальный размер 10 МБ</p>
      <br />
      <div class="img-array">
        <div v-for="(image, i) in imagesShow" :key="i" class="img-array__box">
          <a :href="`${image.link}`" target="_blank" style="opacity: 50%">
            <img id="previewImg" :src="image.icon" />
          </a>
          <button class="img-array__btn" @click="BtnReset(i)">X</button>
        </div>
        <div v-for="(image, i) in imagesShowEdit" :key="i" class="img-array__box">
          <a :href="`${appUrl + '/' + image.path}`" target="_blank">
            <img v-if="image.type" :src="image.icon" alt="" />
            <img v-else :src="$url + '/' + image.path" alt="" />
          </a>
          <span class="img-array__btn" @click="BtnResetPost(image.id, i)">X</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataImg: {
      type: Object,
      default: () => {},
    },
    dataImgList: {
      type: Array,
      default: () => [],
    },
    dataDocList: {
      type: Array,
      default: () => [],
    },
    disabledShow: {
      type: [String, Boolean],
      default: false,
    },
    typeOf: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'large'
    }
  },
  data() {
    return {
      isInputshow: true,
      isInputAdd: false,
      imagesShow: [],
      imagesShowEdit: [],
      images: [],
    };
  },
  watch: {
    "$route.query"() {
      if (!this.$route.query.id) {
        this.imagesShowEdit = [];
        this.isInputshow = true;
      }
    },
  },
  mounted() {
    this.productList();
  },
  created() {
    this.productList();
  },
  methods: {
    previewFile(e) {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.imagesShow.push(reader.result);
      };
      e.target.files[0]
        ? (reader.readAsDataURL(e.target.files[0]), (this.isInputshow = false))
        : "";
    },
    previewFileAll(e) {
      const app = this;
      for (let i = 0; i < e.target.files.length; i++) {
        app.images.push(e.target.files[i]);

        const type =
          e.target.files[i].name.split(".")[e.target.files[i].name.split(".").length - 1];
        let reader = new FileReader();
        reader.onload = (e) => {
          let icon;
          if (type === "jpg" || type === "png") {
            icon = e.target.result;
          } else {
            icon = this.docIconSwitch(type);
          }
          app.imagesShow.push({
            link: e.target.result,
            icon: icon,
          });
        };
        if (e.target.files[0].size < 1000 * 1024) {
          e.target.files[i]
            ? (reader.readAsDataURL(e.target.files[i]), (this.isInputshow = false))
            : "";
        } else {
          this.$toast.error('Размер файла слишком велик')
          app.images.pop();
        }
      }
      this.$emit("images", app.images);
      app.$store.commit("imagesAdd", app.images);
    },
    docIconSwitch(value) {
      switch (value.toLowerCase()) {
        case "svg":
          return "https://icon-library.com/images/filetype-type-format-extension-document-file_55-512.png";
        case "docx":
          return "https://findicons.com/files/icons/2795/office_2013_hd/2000/word.png";
        case "pptx":
          return "https://www.aupn.org/images/PowerPoint_icon_sm.png";
        case "xlsx":
          return "https://cdn.cnetcontent.com/91/46/914674b7-97c2-498e-ad3a-08e4823467cb.jpg";
        case "pdf":
          return "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Icon_pdf_file.svg/1200px-Icon_pdf_file.svg.png";
        case "zip":
          return "https://previews.123rf.com/images/feelisgood/feelisgood1709/feelisgood170902218/86150765-verwenden-sie-es-in-allen-ihren-designs-dateinamenerweiterungssymbol-zip-archiv-dateiformat-im-flach.jpg";
        default:
          return "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/.txt_OneDrive_icon.svg/1024px-.txt_OneDrive_icon.svg.png";
      }
    },
    BtnReset(i) {
      this.imagesShow.splice(i, 1);
      if (this.imagesShowEdit.length === 0)
            this.isInputshow = true
      this.$emit("images", this.imagesShow);
    },
    async BtnResetPost(id, i) {
      var url = `/web/transport-image-delete?image_id=${id}`
      if (this.typeOf === 'doc')
        url = `/web/transport-document-delete?document_id=${id}`
      await this.$api
        .delete(url)
        .then(() => {
          this.imagesShowEdit.splice(i, 1)
          if (this.imagesShowEdit.length === 0)
            this.isInputshow = true
          this.$toast.success('Успешно удалили');
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        });
    },
    productList() {
      if (this.dataImgList.length) {
        this.imagesShowEdit = this.dataImgList;
        if (this.imagesShowEdit.length > 0) this.isInputshow = false;
        else {
          this.isInputshow = true;
        }
        this.imagesShowEdit.forEach((e) => {
          if (e.path) {
            let icon = e.path.split(".")[e.path.split(".").length - 1];
            if (icon !== "jpg" && icon !== "png") {
              e.type = "doc";
              e.icon = this.docIconSwitch(icon);
            }
          }
        });
      }
      if (this.dataDocList.length) {
        this.imagesShowEdit = this.dataDocList;
        if (this.imagesShowEdit.length) this.isInputshow = false;
        else {
          this.isInputshow = true;
        }
        this.imagesShowEdit.forEach((e) => {
          if (e.path) {
            let icon = e.path.split(".")[e.path.split(".").length - 1];
            if (icon !== "jpg" && icon !== "png") {
              e.type = "doc";
              e.icon = this.docIconSwitch(icon);
            }
          }
        });
      }
    },
    asd(x){
      return x.path
    }
  },
  computed: {
    appUrl() {
      return process.env.VUE_APP_URL
    },
    style() {
      if (this.size === 'small')
        return 'min-height: 100px'
      return ''
    }
  }
};
</script>

<style scoped>
.img-created {
  text-align: center;
  background-color: #f7f7f7;
  width: 100%;
}
.block {
  min-height: 250px;
  width: 100%;
  background-color: #fff;
  border: 2px dashed #0087f7;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.img-icon {
  margin: 15px 0;
}
.img-title {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 500; /* used to be 600 with Gilroy*/
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #707070;
  margin: 0;
}
.img-text {
  margin: 10px 0;
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
}
.img-btn {
  padding: 10px;
  border: 1px solid #e0e9fa;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #707070;
}
.block input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputshow {
  opacity: 0;
}
.inputAdd {
  border: 4px dashed #4a504c;
  z-index: 1000;
}
.img-array {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0 20px 0px;
}
.img-array:hover {
  z-index: 2;
}
.img-array:hover + .block input {
  z-index: -1;
}
.img-array img {
  max-width: 100px;
  max-height: 80px;
  margin: 5px;
  padding: 5px;
  border: 1px solid #606aff;
}
.img-array__box {
  position: relative;
  margin-right: 5px;
}
.img-array__btn {
  padding: 0;
  border-radius: 10px;
  background-color: rgb(228, 227, 226);
  position: absolute;
  top: 0%;
  left: 75%;
  font-size: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>
